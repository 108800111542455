<template>
  <div v-if="user && ([1].includes(user.groups[0]) || user.is_superuser)">
    <!-- Botón flotante IA -->
    <template v-if="show_button_ai">
      <b-button v-b-toggle.sidebar-right class="btn-ai noprint" title="IA">
        <img src="../../assets/icon-ai.svg" alt="" class="icon-btn-ai" />
      </b-button>
    </template>

    <!-- Sidebar -->
    <b-sidebar
      id="sidebar-right"
      text-variant="light"
      bg-variant="dark"
      right
      shadow
      z-index="1051"
      width="355px"
      :backdrop="!isPinned"
      backdrop-variant="none"
      :no-close-on-backdrop="isPinned"
    >
      <!-- Header del Sidebar -->
      <template #header>
        <div class="container-header-sidebar">
          <div>
            <b-icon-x
              variant="light"
              class="mt-1"
              v-b-toggle.sidebar-right
            ></b-icon-x>
            <b-icon-pin
              v-if="!isPinned"
              @click="togglePin"
              class="mt-1"
              scale="0.7"
              v-b-tooltip.v-secondary.top.noninteractive="`Fijar Menú`"
            ></b-icon-pin>
            <b-icon-pin-fill
              v-else
              @click="togglePin"
              class="mt-1"
              scale="0.7"
              v-b-tooltip.v-secondary.top.noninteractive="`Desfijar Menú`"
            ></b-icon-pin-fill>
          </div>

          <h4>Inteligencia Artificial</h4>
        </div>
      </template>

      <!-- Body del Sidebar -->
      <div>
        <b-tabs justified card class="custom-tabs" v-model="active_tab">
          <!-- Pestaña N°1 -->
          <b-tab title="Generador" active>
            <b-card header-tag="header" class="card-options mt-1">
              <template #header>
                <div class="d-flex justify-content-center align-items-center">
                  <h4 class="flex-grow-1 text-center m-0">Opciones</h4>
                  <b-icon
                    icon="gear-fill"
                    class="ml-auto icon-settings"
                    @click="advancedSettings"
                    v-b-tooltip.v-secondary.noninteractive="
                      'Opciones Avanzadas'
                    "
                  ></b-icon>
                </div>
              </template>
              <b-card-body class="body-card-container">
                <div class="text-left">
                  <!-- Selector del Tipo  -->
                  <div class="mb-2">
                    <span> ¿Qué desea crear?: </span>
                    <v-select
                      id="backdrop-variant"
                      :options="intelligenceOptions"
                      :reduce="(intelligenceOptions) => intelligenceOptions.id"
                      v-model="selected_intelligence_option"
                      :dropdown-should-open="dropdownShouldOpenType"
                      label="label"
                      track-by="id"
                      :placeholder="'-- Seleccione una opción --'"
                      class="style-chooser mt-2"
                      @input="clearInfoSelector"
                      :disabled="
                        disabled_selected_intelligence_option &&
                        selected_intelligence_option != null
                      "
                    >
                      <!-- template para dejar la opción en una sola linea -->
                      <template #selected-option="{ label }">
                        <span class="selected-option-vue-select">
                          <!-- v-b-tooltip.v-secondary.noninteractive="`${label}`" -->
                          {{ label }}
                        </span>
                      </template>
                      <template v-slot:no-options="{ search, searching }">
                        <template v-if="searching">
                          No se encontraron resultados para: "<em>{{
                            search
                          }}</em
                          >"
                        </template>
                      </template>
                    </v-select>
                  </div>
                  <!-- Selector Perfil de Egreso  -->
                  <div
                    v-if="
                      [1, 2, 3, 4, 5, 6].includes(selected_intelligence_option)
                    "
                    class="mb-2"
                  >
                    <span>
                      Seleccione
                      {{
                        $getVisibleNames(
                          "mesh.egressprofile",
                          false,
                          "Perfil de Egreso"
                        )
                      }}:
                    </span>
                    <v-select
                      id="egress-profile-selector"
                      :options="egress_profiles"
                      :reduce="(egress_profiles) => egress_profiles.id"
                      v-model="selected_egress_profile"
                      :dropdown-should-open="dropdownShouldOpenEgressProfile"
                      label="name"
                      track-by="id"
                      :placeholder="'-- Seleccione una opción --'"
                      class="style-chooser mt-2"
                      @input="fetchEgressProfile"
                      :disabled="
                        disabled_selected_egress_profile &&
                        selected_egress_profile != null
                      "
                    >
                      <!-- template para dejar la opción en una sola linea -->
                      <template #selected-option="{ name }">
                        <span
                          class="selected-option-vue-select"
                          v-b-tooltip.v-secondary.noninteractive="`${name}`"
                        >
                          {{ name }}
                        </span>
                      </template>
                      <template v-slot:no-options="{ search, searching }">
                        <template v-if="searching">
                          No se encontraron resultados para: "<em>{{
                            search
                          }}</em
                          >"
                        </template>
                      </template>
                    </v-select>
                  </div>
                  <!-- Selector Asignatura  -->
                  <div
                    v-if="
                      [2, 3, 4, 5, 6].includes(selected_intelligence_option)
                    "
                    class="mb-2"
                  >
                    <span>
                      Seleccione
                      {{
                        $getVisibleNames(
                          "mesh.egressprofilematter",
                          false,
                          "Asignatura"
                        )
                      }}:
                    </span>
                    <v-select
                      id="matters-selector"
                      :options="egressProfileMatterOptions"
                      :reduce="
                        (egressProfileMatterOptions) =>
                          egressProfileMatterOptions.id
                      "
                      v-model="selected_egress_profile_matter"
                      :dropdown-should-open="dropdownShouldOpenMatter"
                      label="label"
                      track-by="id"
                      :placeholder="'-- Seleccione una opción --'"
                      class="mt-2 style-chooser"
                      @input="fetchStudyUnit"
                      :disabled="
                        disabled_selected_egress_profile_matter &&
                        selected_egress_profile_matter != null
                      "
                    >
                      <!-- template para dejar la opción en una sola linea -->
                      <template #selected-option="{ name }">
                        <span
                          class="selected-option-vue-select"
                          v-b-tooltip.v-secondary.noninteractive="`${name}`"
                        >
                          {{ name }}
                        </span>
                      </template>
                      <template v-slot:no-options="{ search, searching }">
                        <template v-if="searching">
                          No se encontraron resultados para: "<em>{{
                            search
                          }}</em
                          >"
                        </template>
                      </template>
                    </v-select>
                  </div>
                  <!-- Selector Unidad de Competencia  -->
                  <div
                    v-if="
                      [2, 3].includes(selected_intelligence_option) &&
                      institution &&
                      institution.internal_use_id == 'ciisa_uss'
                    "
                    class="mb-2"
                  >
                    <span>
                      Seleccione
                      {{
                        $getVisibleNames(
                          "teaching.tempcompetenceunit",
                          false,
                          "Unidad de competencia"
                        )
                      }}:
                    </span>
                    <v-select
                      id="study-unit-selector"
                      :options="competenceUnitOptions"
                      :reduce="
                        (competenceUnitOptions) => competenceUnitOptions.id
                      "
                      v-model="selected_competence_unit"
                      :dropdown-should-open="dropdownShouldOpenStudyUnit"
                      track-by="id"
                      label="full_sentence"
                      :placeholder="'-- Seleccione una opción --'"
                      class="mt-2 style-chooser"
                      :disabled="
                        disabled_selected_competence_unit &&
                        selected_competence_unit != null
                      "
                    >
                      <!-- template para dejar la opción en una sola linea -->
                      <template #selected-option="{ full_sentence }">
                        <span
                          class="selected-option-vue-select"
                          v-b-tooltip.v-secondary.noninteractive="
                            `${full_sentence}`
                          "
                        >
                          {{ full_sentence }}
                        </span>
                      </template>
                      <template v-slot:no-options="{ search, searching }">
                        <template v-if="searching">
                          No se encontraron resultados para: "<em>{{
                            search
                          }}</em
                          >"
                        </template>
                      </template>
                    </v-select>
                  </div>
                  <!-- Selector RA  -->
                  <div
                    v-if="[3].includes(selected_intelligence_option)"
                    class="mb-2"
                  >
                    <span>
                      Seleccione
                      {{
                        $getVisibleNames("teaching.ramicro", false, "RA Micro")
                      }}:
                    </span>
                    <v-select
                      id="study-unit-selector"
                      :options="studyUnitOptions"
                      :reduce="(studyUnitOptions) => studyUnitOptions.id"
                      v-model="selected_study_unit"
                      :dropdown-should-open="dropdownShouldOpenStudyUnit"
                      label="full_sentence"
                      track-by="id"
                      :placeholder="'-- Seleccione una opción --'"
                      class="mt-2 style-chooser"
                      :disabled="
                        disabled_selected_study_unit &&
                        selected_study_unit != null
                      "
                    >
                      <!-- template para dejar la opción en una sola linea -->
                      <template #selected-option="{ full_sentence }">
                        <span
                          class="selected-option-vue-select"
                          v-b-tooltip.v-secondary.noninteractive="
                            `${full_sentence}`
                          "
                        >
                          {{ full_sentence }}
                        </span>
                      </template>
                      <template v-slot:no-options="{ search, searching }">
                        <template v-if="searching">
                          No se encontraron resultados para: "<em>{{
                            search
                          }}</em
                          >"
                        </template>
                      </template>
                    </v-select>
                  </div>
                </div>
                <!-- Cantidad -->
                <div class="mt-0 text-left">
                  <span>
                    Seleccione la cantidad de ejemplos que desee generar.
                  </span>
                  <div class="container-checkbox-amount mt-1">
                    <b-form-checkbox
                      id="checkbox-1"
                      v-model="selected_example_amount"
                      :value="1"
                      @change="updateTokens"
                    >
                      1
                    </b-form-checkbox>
                    <b-form-checkbox
                      id="checkbox-3"
                      v-model="selected_example_amount"
                      :value="3"
                      @change="updateTokens"
                    >
                      3
                    </b-form-checkbox>
                    <b-form-checkbox
                      id="checkbox-5"
                      v-model="selected_example_amount"
                      :value="5"
                      @change="updateTokens"
                    >
                      5
                    </b-form-checkbox>
                  </div>
                </div>
              </b-card-body>
              <b-card-footer>
                <b-button
                  v-if="disabledButtonGenerateExamples"
                  variant="primary"
                  size="sm"
                  :disabled="true"
                  >Generar Ejemplos
                </b-button>
                <b-button
                  v-else
                  variant="primary"
                  size="sm"
                  @click="generateExamples"
                  :disabled="disabled_button_generate == true"
                  >Generar Ejemplos
                </b-button>
              </b-card-footer>
            </b-card>

            <!-- Opciones Avanzadas -->
            <b-card
              v-if="advanced_settings"
              header-tag="header"
              class="card-options mt-2"
            >
              <template #header>
                <div class="d-flex justify-content-center align-items-center">
                  <h4 class="flex-grow-1 text-center m-0">
                    Opciones Avanzadas
                  </h4>
                  <b-icon
                    icon="x"
                    scale="1.2"
                    class="ml-auto icon-settings"
                    @click="advancedSettings"
                    v-b-tooltip.v-secondary.noninteractive="
                      'Opciones Avanzadas'
                    "
                  ></b-icon>
                </div>
              </template>
              <!-- Body de la Tarjeta -->
              <b-card-body class="body-card-container">
                <!-- Temperatura -->
                <div class="mt-0">
                  <div class="text-left">
                    <div class="container-options-advanced">
                      <span>Temperatura: </span>
                      <b-icon
                        icon="info-circle-fill"
                        scale="1"
                        variant="primary"
                        class="icon-help"
                        v-b-tooltip.hover.v-secondary.left="
                          `Controla la aleatoriedad de los resultados. Valores bajos (como 0.2) hacen que las respuestas sean más predecibles, mientras que valores altos (como 0.8) generan respuestas más creativas.`
                        "
                      >
                      </b-icon>
                    </div>
                    <b-form-input
                      type="range"
                      min="0"
                      max="1"
                      step="0.1"
                      v-model="temperature_value"
                      id="myRange"
                      class="mb-0 pb-0"
                    >
                    </b-form-input>
                  </div>
                  {{ temperature_value }}
                </div>
                <!-- Tokens Maximos -->
                <div class="mt-0">
                  <div class="text-left">
                    <div class="container-options-advanced">
                      <span>Cantidad de tokens máximos: </span>
                      <b-icon
                        icon="info-circle-fill"
                        scale="1"
                        variant="primary"
                        class="icon-help"
                        v-b-tooltip.hover.v-secondary.left="
                          `Limita el número máximo de tokens (unidad de palabras y fragmentos de palabras) que la respuesta puede contener.`
                        "
                      >
                      </b-icon>
                    </div>
                    <b-form-input
                      type="range"
                      min="50"
                      max="300"
                      v-model="tokens_value"
                      id="myRange"
                      class="mb-0 pb-0"
                    >
                    </b-form-input>
                  </div>
                  {{ tokens_value }}
                </div>
              </b-card-body>
            </b-card>
          </b-tab>

          <!-- Segunda pestaña -->
          <b-tab title="Resultados">
            <!-- Skeleton -->
            <b-card v-if="is_loading && !msg_error" class="mt-1 p-3">
              <div class="d-flex">
                <b-skeleton-icon
                  icon="record-circle-fill"
                  :icon-props="{ fontScale: 1, variant: 'dark' }"
                ></b-skeleton-icon>
                <h5 class="ml-1 text-dark">Generando Ejemplos...</h5>
              </div>
              <b-skeleton animation="fade" width="85%"></b-skeleton>
              <b-skeleton animation="fade" width="55%"></b-skeleton>
              <b-skeleton animation="fade" width="70%"></b-skeleton>
              <b-skeleton animation="fade" width="90%"></b-skeleton>
            </b-card>
            <b-card
              v-else-if="result_examples.length == 0"
              header-tag="header"
              class="card-options mt-1"
            >
              <template #header>
                <div class="d-flex justify-content-center">
                  <h4>Resultados</h4>
                </div>
              </template>
              <!-- Body de la Tarjeta -->
              <b-card-body class="body-card-container">
                <div class="text-left">
                  <p class="m-0 my-1 p-0">Aún no ha generado ningún ejemplo.</p>
                </div>
              </b-card-body>
            </b-card>

            <!-- Resultados -->
            <b-card
              v-if="result_examples.length > 0"
              header-tag="header"
              class="card-options mt-1"
            >
              <template #header>
                <div class="d-flex justify-content-center">
                  <h4>Resultados</h4>
                </div>
              </template>
              <!-- Body de la Tarjeta -->
              <b-card-body class="body-card-container">
                <div class="text-left">
                  <!-- Perfil de Egreso -->
                  <div v-if="result_examples.length > 0">
                    <div
                      v-for="(example, index) in result_examples"
                      :key="index"
                      class="d-flex text-justify"
                    >
                      <p class="m-0 mt-1 p-0">
                        {{ example }}
                      </p>
                      <b-button
                        :variant="copiedExamples[index] ? 'success' : 'primary'"
                        size="sm"
                        @click="copyToClipboard(example, index)"
                        :disabled="copiedExamples[index]"
                        class="icon-copy-clipboard ml-2 mt-2 p-1 align-self-start"
                        v-b-tooltip.v-secondary.noninteractive.hover.top="
                          `Copiar ejemplo.`
                        "
                      >
                        <b-icon
                          :icon="
                            copiedExamples[index]
                              ? 'clipboard-check'
                              : 'clipboard'
                          "
                        ></b-icon>
                      </b-button>
                    </div>
                  </div>
                </div>
              </b-card-body>
            </b-card>
          </b-tab>
        </b-tabs>
      </div>
    </b-sidebar>
  </div>
</template>

<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { toast } from "@/utils/utils";

export default {
  name: "AiComponent",
  components: {
    vSelect,
  },
  props: {},
  data() {
    return {
      isPinned: false, // Controla si el sidebar está fijado o no
      advanced_settings: false, // Variable para visualizar "Opciones Avanzadas"
      temperature_value: 0.5,
      tokens_value: 150,
      active_tab: 0, // Variable para mostrar los "tabs"
      is_loading: false, // Variable para mostrar "skeletons"
      disabled_button_generate: false, // Variable para deshabilitar boton cuando se genera un ejemplo
      msg_error: false, // Variable para controlar el mensaje de error en el template
      copiedExamples: [], // Arreglo para rastrear ejemplos copiados
      parameter_local_storage: false, // Variable para los datos del Local Storage
      selected_intelligence_option: null,
      selected_egress_profile: null,
      selected_egress_profile_matter: null,
      selected_competence_unit: null,
      selected_study_unit: null,
      selected_example_amount: 3,
      result_examples: [],
      show_button_ai: false,
      disabled_selected_intelligence_option: false,
      disabled_selected_egress_profile: false,
      disabled_selected_egress_profile_matter: false,
      disabled_selected_competence_unit: false,
      disabled_selected_study_unit: false,
    };
  },
  computed: {
    ...mapGetters({
      user: "getUser",
      institution: "getInstitution",
      show_intelligence_sidebar: names.SHOW_INTELLIGENCE_SIDEBAR,
      filter_intelligence: names.FILTER_INTELLIGENCE,
      intelligence_options: names.INTELLIGENCE_OPTIONS,
      egress_profiles: names.EGRESS_PROFILES,
      egress_profile_matters: names.EGRESS_PROFILES_MATTERS,
      competence_units: names.COMPETENCE_UNITS,
      study_units: names.STUDY_UNITS,
      matters: names.MATTERS,
      rules_filter_selector: names.RULES_FILTER_SELECTOR,
    }),
    intelligenceOptions() {
      let list = this.intelligence_options
        .filter((x) => {
          // Siempre excluye el id 4, excepto en el caso de "ciisa_uss"
          if (
            x.id == 4 &&
            (!this.institution ||
              this.institution.internal_use_id != "ciisa_uss")
          ) {
            return false;
          }
          // Incluye id 5 solo si show_ra_macro es true
          if (x.id == 5) {
            return this.institution && this.institution.show_ra_macro == true;
          }
          // Incluye id 6 solo si show_evaluation_criteria_macro es true
          if (x.id == 6) {
            return (
              this.institution &&
              this.institution.show_evaluation_criteria_macro == true
            );
          }
          // Incluye todos los demás ids
          return true;
        })
        .map((x) => {
          let labelOption = false;

          // Si el N° de ejemplos es 1 se coloca en singular
          if (this.selected_example_amount == 1) {
            labelOption = false;
          }
          // Si el N° de ejemplos es 3 o 5 se coloca en plural
          else if (
            this.selected_example_amount == 3 ||
            this.selected_example_amount == 5
          ) {
            labelOption = true;
          }
          return {
            ...x,
            label: this.$getVisibleNames(x.app_models, labelOption),
          };
        });
      // Aplica el filtro adicional si está configurado
      if (
        this.rules_filter_selector &&
        this.rules_filter_selector.visible_intelligence_options &&
        this.rules_filter_selector.visible_intelligence_options.length > 0
      ) {
        list = list.filter((x) =>
          this.rules_filter_selector.visible_intelligence_options.includes(x.id)
        );
      }
      return list;
    },
    egressProfileMatterOptions() {
      let list = [];
      if (this.selected_egress_profile == null) return [];
      list = this.egress_profile_matters.filter(
        (x) => x.egress_profile == this.selected_egress_profile
      );
      list.forEach((x) => {
        const matter = this.matters.find((matter) => matter.id == x.matter);
        if (matter) {
          x.label = String(matter.code + " - " + matter.name);
          x.name = matter.name;
        } else {
          x.label = "";
          x.name = "";
        }
      });
      return list.sort(function (a, b) {
        if (a.order > b.order) return 1;
        if (a.order < b.order) return -1;
        return 0;
      });
    },
    competenceUnitOptions() {
      let list = [];
      if (this.selected_egress_profile_matter == null) return [];
      const matter = this.egress_profile_matters.find(
        (x) => x.id == this.selected_egress_profile_matter
      );
      if (matter) {
        list = this.competence_units.filter((x) => x.matter == matter.matter);
      }
      return list.sort(function (a, b) {
        if (a.order > b.order) return 1;
        if (a.order < b.order) return -1;
        return 0;
      });
    },
    studyUnitOptions() {
      let list = [];
      if (this.selected_egress_profile_matter == null) return [];
      const matter = this.egress_profile_matters.find(
        (x) => x.id == this.selected_egress_profile_matter
      );
      if (matter) {
        list = this.study_units.filter((x) => x.matter == matter.matter);
      }
      return list.sort(function (a, b) {
        if (a.order > b.order) return 1;
        if (a.order < b.order) return -1;
        return 0;
      });
    },
    disabledButtonGenerateExamples() {
      // Opción generar Competencia
      if (
        this.selected_intelligence_option == 1 &&
        this.selected_egress_profile == null
      ) {
        return true;
      }
      // Opción generar Unidad de Estudio
      if (
        this.institution &&
        this.institution.internal_use_id != "duoc_uc" &&
        this.institution.internal_use_id != "ciisa_uss" &&
        this.selected_intelligence_option == 2 &&
        (this.selected_egress_profile == null ||
          this.selected_egress_profile_matter == null)
      ) {
        return true;
      }
      if (
        this.institution &&
        this.institution.internal_use_id == "duoc_uc" &&
        this.selected_intelligence_option == 2 &&
        (this.selected_egress_profile == null ||
          this.selected_egress_profile_matter == null)
      ) {
        return true;
      }
      if (
        this.institution &&
        this.institution.internal_use_id == "ciisa_uss" &&
        this.selected_intelligence_option == 2 &&
        (this.selected_egress_profile == null ||
          this.selected_egress_profile_matter == null ||
          this.selected_competence_unit == null)
      ) {
        return true;
      }

      // Opción generar Indicador de Logro
      if (
        this.institution &&
        this.institution.internal_use_id != "duoc_uc" &&
        this.institution.internal_use_id != "ciisa_uss" &&
        this.selected_intelligence_option == 3 &&
        (this.selected_egress_profile == null ||
          this.selected_egress_profile_matter == null ||
          this.selected_study_unit == null)
      ) {
        return true;
      }
      if (
        this.institution &&
        this.institution.internal_use_id == "duoc_uc" &&
        this.selected_intelligence_option == 3 &&
        (this.selected_egress_profile == null ||
          this.selected_egress_profile_matter == null ||
          this.selected_study_unit == null)
      ) {
        return true;
      }
      if (
        this.institution &&
        this.institution.internal_use_id == "ciisa_uss" &&
        this.selected_intelligence_option == 3 &&
        (this.selected_egress_profile == null ||
          this.selected_egress_profile_matter == null ||
          this.selected_study_unit == null ||
          this.selected_competence_unit == null)
      ) {
        return true;
      }

      // Opción generar Unidad de Competencia (IPSS)
      if (
        this.selected_intelligence_option == 4 &&
        (this.selected_egress_profile == null ||
          this.selected_egress_profile_matter == null)
      ) {
        return true;
      }
      // Opción generar RA Macro
      if (
        this.selected_intelligence_option == 5 &&
        (this.selected_egress_profile == null ||
          this.selected_egress_profile_matter == null)
      ) {
        return true;
      }
      // Opción generar Evaluation Criteria Macro
      if (
        this.selected_intelligence_option == 6 &&
        (this.selected_egress_profile == null ||
          this.selected_egress_profile_matter == null)
      ) {
        return true;
      }
      return false;
    },
  },
  methods: {
    dropdownShouldOpenEgressProfile(VueSelect) {
      if (this.egress_profiles.length > 0) {
        return VueSelect.open;
      }
      return VueSelect.search.length !== 0 && VueSelect.open;
    },
    dropdownShouldOpenType(VueSelect) {
      if (this.intelligenceOptions.length > 0) {
        return VueSelect.open;
      }
      return VueSelect.search.length !== 0 && VueSelect.open;
    },
    dropdownShouldOpenStudyUnit(VueSelect) {
      if (this.studyUnitOptions.length > 0) {
        return VueSelect.open;
      }
      return VueSelect.search.length !== 0 && VueSelect.open;
    },
    dropdownShouldOpenMatter(VueSelect) {
      if (this.egressProfileMatterOptions.length > 0) {
        return VueSelect.open;
      }
      return VueSelect.search.length !== 0 && VueSelect.open;
    },
    generateExamples() {
      this.active_tab = 1;
      this.is_loading = true;
      this.disabled_button_generate = true;
      this.result_examples = [];
      this.msg_error = false;

      // setTimeout(() => {
      //   this.result_examples =
      //     "1. Analizar datos de comportamiento del consumidor, utilizando herramientas de análisis digital, en el contexto de campañas de marketing online.\n\n2. Diseñar estrategias de contenido, aplicando principios de SEO y SEM, en el contexto de la promoción de productos y servicios en plataformas digitales.\n\n3. Gestionar proyectos de marketing digital, implementando metodologías ágiles, en el contexto de la colaboración con equipos multidisciplinarios.".split(
      //       "\n\n"
      //     );
      //   this.is_loading = false; // Desactiva el estado de carga después del retraso
      //   this.disabled_button_generate = false; // Vuelve a habilitar el boton de "Generar Ejemplos"
      // }, 5000);
      let payload = {};
      if (this.selected_egress_profile)
        payload.egress_profile = this.selected_egress_profile;
      if (this.selected_egress_profile_matter)
        payload.egress_profile_matter = this.selected_egress_profile_matter;
      if (this.selected_competence_unit)
        payload.competence_unit = this.selected_competence_unit;
      if (this.selected_study_unit)
        payload.study_unit = this.selected_study_unit;
      if (this.selected_intelligence_option)
        payload.inteligence_option = this.selected_intelligence_option;
      if (this.selected_example_amount)
        payload.number_example = this.selected_example_amount;
      if (this.tokens_value) payload.max_tokens = this.tokens_value;
      if (this.temperature_value) payload.temperature = this.temperature_value;
      this.$restful
        .Post(`/common/assessment-builder-ai/`, payload)
        .then((response) => {
          this.is_loading = false;
          this.disabled_button_generate = false;
          this.result_examples = response
            .split(/\n{1,}/)
            .filter((part) => part.trim() !== "");

          // Uso del Local Storage
          // Parameters AI
          this.$store.commit("setLocalVariable", {
            component: this.$route.name,
            variable: this.urlLocalStorage(),
            value: {
              egress_profile: this.selected_egress_profile,
              egress_profile_matter: this.selected_egress_profile_matter,
              competence_unit: this.selected_competence_unit,
              study_unit: this.selected_study_unit,
              intelligence_option: this.selected_intelligence_option,
              example_amount: this.selected_example_amount,
              temperature_value: this.temperature_value,
              tokens_value: this.tokens_value,
              result_examples: this.result_examples,
            },
          });
        })
        .catch(() => {
          this.is_loading = false;
          this.disabled_button_generate = false;
          this.msg_error = true;
          this.result_examples = [
            "Ocurrió un error al generar los ejemplos. Por favor, intenta nuevamente.",
          ];
        });
    },
    urlLocalStorage() {
      let arr = [];
      // Opción generar Competencia
      if (this.selected_intelligence_option == 1) {
        arr.push(`competence=${this.selected_egress_profile}`);
      }
      // Opción generar Unidad de Estudio
      if (this.selected_intelligence_option == 2) {
        if (
          this.institution &&
          this.institution.internal_use_id != "duoc_uc" &&
          this.institution.internal_use_id != "ciisa_uss"
        ) {
          arr.push(
            `study_unit=${this.selected_egress_profile}&${this.selected_egress_profile_matter}`
          );
        }
        if (this.institution && this.institution.internal_use_id == "duoc_uc") {
          arr.push(
            `study_unit=${this.selected_egress_profile}&${this.selected_egress_profile_matter}`
          );
        }
        if (
          this.institution &&
          this.institution.internal_use_id == "ciisa_uss"
        ) {
          arr.push(
            `study_unit=${this.selected_egress_profile}&${this.selected_egress_profile_matter}&${this.selected_competence_unit}`
          );
        }
      }
      // Opción generar Indicador de Logro
      if (this.selected_intelligence_option == 3) {
        if (
          this.institution &&
          this.institution.internal_use_id != "duoc_uc" &&
          this.institution.internal_use_id != "ciisa_uss"
        ) {
          arr.push(
            `evaluation_criteria_micro=${this.selected_egress_profile}&${this.selected_egress_profile_matter}&${this.selected_study_unit}`
          );
        }
        if (this.institution && this.institution.internal_use_id == "duoc_uc") {
          arr.push(
            `evaluation_criteria_micro=${this.selected_egress_profile}&${this.selected_egress_profile_matter}&${this.selected_study_unit}`
          );
        }
        if (
          this.institution &&
          this.institution.internal_use_id == "ciisa_uss"
        ) {
          arr.push(
            `evaluation_criteria_micro=${this.selected_egress_profile}&${this.selected_egress_profile_matter}&${this.selected_competence_unit}`
          );
        }
      }
      // Opción generar Unidad de Competencia (IPSS)
      if (this.selected_intelligence_option == 4) {
        arr.push(
          `competence_unit=${this.selected_egress_profile}&${this.selected_egress_profile_matter}`
        );
      }
      // Opción generar RA Macro
      if (this.selected_intelligence_option == 5) {
        arr.push(
          `ra_macro=${this.selected_egress_profile}&${this.selected_egress_profile_matter}`
        );
      }
      // Opción generar Evaluation Criteria Macro
      if (this.selected_intelligence_option == 6) {
        arr.push(
          `evaluation_criteria_macro=${this.selected_egress_profile}&${this.selected_egress_profile_matter}`
        );
      }
      const url = `${arr.join("&")}`;
      return url;
    },
    copyToClipboard(text, index) {
      const textWithoutNumber = text.replace(/^\d+\.\s*/, ""); // Elimina el número al inicio si existe

      navigator.clipboard
        .writeText(textWithoutNumber)
        .then(() => {
          toast("Ejemplo copiado en el portapapeles");
          this.$root.$emit("bv::hide::tooltip"); // Oculta los tooltip del componente para que no se queden fijos al momento de apretar un botón
          this.$set(this.copiedExamples, index, true); // Cambia el icono al 'clipboard-check'
          setTimeout(() => {
            this.$set(this.copiedExamples, index, false); // Cambia el icono al estado original 'clipboard'
          }, 2000);
        })
        .catch((err) => {
          console.error("Error al copiar el texto: ", err);
        });
    },
    advancedSettings() {
      this.advanced_settings = !this.advanced_settings;
    },
    togglePin() {
      this.isPinned = !this.isPinned; // Cambia entre fijado y no fijado
    },
    updateTokens() {
      if (this.selected_example_amount == 1) {
        this.tokens_value = 50;
      } else if (this.selected_example_amount == 3) {
        this.tokens_value = 150;
      } else if (this.selected_example_amount == 5) {
        this.tokens_value = 200;
      }
    },
    clearInfoSelector() {
      if (this.selected_intelligence_option == 1) {
        this.selected_egress_profile_matter = null;
        this.selected_study_unit = null;
      }
      if (this.selected_intelligence_option == 2) {
        this.selected_study_unit = null;
      }
    },
    fetchEgressProfile() {
      this.selected_egress_profile_matter = null;
      this.selected_study_unit = null;
      if (this.selected_egress_profile) {
        this.$store.dispatch(names.FETCH_MATTERS, {
          egress_profile_id: this.selected_egress_profile,
        });
        this.$store.dispatch(names.FETCH_EGRESS_PROFILES_MATTERS, {
          egress_profile_id: this.selected_egress_profile,
        });
      }
    },
    fetchStudyUnit() {
      this.selected_study_unit = null;
      if (this.selected_egress_profile_matter) {
        const matter = this.egress_profile_matters.find(
          (x) => x.id == this.selected_egress_profile_matter
        );
        if (matter) {
          this.$store.dispatch(names.FETCH_STUDY_UNITS, matter.matter);
        }
      }
    },
  },
  watch: {
    show_intelligence_sidebar(value) {
      this.show_button_ai = value;
    },
    filter_intelligence(value) {
      this.active_tab = 0;
      if (value) {
        // Flujo normal cuando recibe valores desde los componentes mediante los "MUTATE"
        switch (value.intelligence_option) {
          case 1: // Opcion 1: Competencia
            this.selected_intelligence_option = value.intelligence_option;
            this.selected_egress_profile = value.egress_profile_id;
            break;
          case 2: // Opcion 2: Unidad de Estudio
            this.selected_intelligence_option = value.intelligence_option;
            this.selected_egress_profile = value.egress_profile_id;
            this.selected_egress_profile_matter =
              value.egress_profile_matter_id;
            this.selected_competence_unit = value.competence_unit_id;
            break;
          case 3: // Opcion 3: Indicador de Logro
            this.selected_intelligence_option = value.intelligence_option;
            this.selected_egress_profile = value.egress_profile_id;
            this.selected_egress_profile_matter =
              value.egress_profile_matter_id;
            this.selected_competence_unit = value.competence_unit_id;
            this.selected_study_unit = value.selected_study_unit_id;
            break;
          case 4: // Opcion 4: Unidad de Competencia (IPSS)
            this.selected_intelligence_option = value.intelligence_option;
            this.selected_egress_profile = value.egress_profile_id;
            this.selected_egress_profile_matter =
              value.egress_profile_matter_id;
            break;
          case 5: // Opcion 5: Resultado de Aprendizaje General
            this.selected_intelligence_option = value.intelligence_option;
            this.selected_egress_profile = value.egress_profile_id;
            this.selected_egress_profile_matter =
              value.egress_profile_matter_id;
            break;
          case 6: // Opcion 6: Indicador de Logro General
            this.selected_intelligence_option = value.intelligence_option;
            this.selected_egress_profile = value.egress_profile_id;
            this.selected_egress_profile_matter =
              value.egress_profile_matter_id;
            break;
        }

        const parameters_ai = this.$store.getters.getLocalVariable(
          this.$route.name,
          this.urlLocalStorage()
        );
        // Valores del LocalStorage
        if (this.parameter_local_storage == false && parameters_ai) {
          this.parameter_local_storage = true;

          // Solo actualiza si son diferentes a los valores en LocalStorage
          if (parameters_ai.egress_profile != this.selected_egress_profile) {
            this.selected_egress_profile = parameters_ai.egress_profile;
          }
          if (
            parameters_ai.egress_profile_matter !=
            this.selected_egress_profile_matter
          ) {
            this.selected_egress_profile_matter =
              parameters_ai.egress_profile_matter;
          }
          if (parameters_ai.competence_unit != this.selected_competence_unit) {
            this.selected_competence_unit = parameters_ai.competence_unit;
          }
          if (parameters_ai.study_unit != this.selected_study_unit) {
            this.selected_study_unit = parameters_ai.study_unit;
          }
          if (
            parameters_ai.intelligence_option !=
            this.selected_intelligence_option
          ) {
            this.selected_intelligence_option =
              parameters_ai.intelligence_option;
          }

          this.selected_example_amount = parameters_ai.example_amount;
          this.temperature_value = parameters_ai.temperature_value;
          this.tokens_value = parameters_ai.tokens_value;
          this.result_examples = parameters_ai.result_examples;
        }
      }
      // Resetea valores cuando `filter_intelligence` es null
      if (value == null) {
        this.selected_intelligence_option = null;
        this.selected_egress_profile = null;
        this.selected_egress_profile_matter = null;
        this.selected_competence_unit = null;
        this.selected_study_unit = null;
        this.parameter_local_storage = false;
        // if (this.user) {
        // localStorage.removeItem(
        //   `${this.$route.name}_parameters_ai_${this.user.id}`
        // );
        // this.selected_example_amount = null;
        // this.temperature_value = null;
        // this.tokens_value = null;
        //}
      }
    },
    rules_filter_selector(value) {
      if (value) {
        if (value.disabled_selected_intelligence_option) {
          this.disabled_selected_intelligence_option =
            value.disabled_selected_intelligence_option;
        }
        if (value.disabled_selected_egress_profile) {
          this.disabled_selected_egress_profile =
            value.disabled_selected_egress_profile;
        }
        if (value.disabled_selected_egress_profile_matter) {
          this.disabled_selected_egress_profile_matter =
            value.disabled_selected_egress_profile_matter;
        }
        if (value.disabled_selected_competence_unit) {
          this.disabled_selected_competence_unit =
            value.disabled_selected_competence_unit;
        }
        if (value.disabled_selected_study_unit) {
          this.disabled_selected_study_unit =
            value.disabled_selected_study_unit;
        }
      }
      if (value == null) {
        this.disabled_selected_intelligence_option = false;
        this.disabled_selected_egress_profile = false;
        this.disabled_selected_egress_profile_matter = false;
        this.disabled_selected_competence_unit = false;
        this.disabled_selected_study_unit = false;
      }
    },
  },
  mounted() {
    // Uso del Local Storage
    // Parameters AI
    const parameters_ai = this.$store.getters.getLocalVariable(
      this.$route.name,
      this.urlLocalStorage()
    );

    if (parameters_ai) {
      this.parameter_local_storage = true;
      this.selected_egress_profile = parameters_ai.egress_profile;
      this.selected_egress_profile_matter = parameters_ai.egress_profile_matter;
      this.selected_competence_unit = parameters_ai.competence_unit;
      this.selected_study_unit = parameters_ai.study_unit;
      this.selected_intelligence_option = parameters_ai.intelligence_option;
      this.selected_example_amount = parameters_ai.example_amount;
      this.temperature_value = parameters_ai.temperature_value;
      this.tokens_value = parameters_ai.tokens_value;
      this.result_examples = parameters_ai.result_examples;
    }
  },
  created() {
    this.$store.dispatch(names.FETCH_EGRESS_PROFILES);
  },
};
</script>

<style scoped>
.sidebar-ai {
  background-color: #2f2f2f !important;
  color: #b4b4b4;
}
.container-header-sidebar {
  display: flex;
}
.btn-ai {
  position: fixed;
  right: 5vw;
  bottom: 6.5vh;
  z-index: 1050;
  border-radius: 50%;
  width: 56px;
  height: 56px;
  background-color: #2c3e50;
  color: white;
  font-size: 1.2rem;
  font-weight: bold;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease;
  border: 1px solid #2c3e50;
  display: flex;
  align-items: center;
  justify-content: center;
}
.btn-ai:hover {
  background-color: #2c3e50;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.3);
  transform: scale(1.05);
}
.icon-copy-clipboard {
  cursor: pointer;
}
.icon-btn-ai {
  width: 40px;
  height: 40px;
}
.card-footer {
  padding: 8px;
}
.card-body {
  padding: 2px;
  min-height: 100%;
}
.card-header {
  padding: 6px;
}
.container-checkbox-amount {
  display: flex;
  justify-content: space-evenly;
}
.card-options {
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  color: #2f2f2f;
  background-color: #f8f9fa;
}
.body-card-container {
  max-height: 75vh;
  overflow-y: auto;
}
.icon-arrow {
  cursor: pointer;
}
.icon-settings {
  cursor: pointer;
}
.container-options-advanced {
  display: flex;
  justify-content: space-between;
}

/* Estilos para los tabs */
.custom-tabs {
  padding: 5px !important;
}
.custom-tabs >>> .nav-link.active {
  background-color: #007bff;
  color: white;
  font-weight: bold;
  border-color: #007bff;
  padding: 0.4rem;
}

.custom-tabs >>> .nav-link {
  background-color: #ddd;
  color: black;
  border-color: #ddd;
  padding: 0.4rem;
}

div >>> .card-header .card-header-tabs {
  margin-left: -1.325rem !important;
}
/* Estilos relacionados con vue-select */
.style-chooser >>> {
  --vs-controls-color: #212020c0;
  --vs-actions-padding: 0px 4px 0px 0px;
}
.style-chooser >>> .vs__search {
  padding: 0px !important;
  width: 0px !important;
}
.style-chooser >>> .vs__selected-options {
  flex-wrap: nowrap;
}
.style-chooser .vs__open-indicator {
  margin-top: 20px;
}
.selected-option-vue-select {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 270px;
}

/* Estilos del scrollbar del sidebar */
div >>> .b-sidebar-body::-webkit-scrollbar {
  width: 3px !important;
}
div >>> .b-sidebar-body::-webkit-scrollbar {
  background: #e2e2e2;
}
div >>> .b-sidebar-body::-webkit-scrollbar-thumb {
  background: #a5a5a5;
  border-radius: 8px;
}
div >>> .b-sidebar-body::-webkit-scrollbar-thumb:hover {
  background: #bbb;
}

/* Estilos para el scrollbar de las tarjetas */
::-webkit-scrollbar {
  width: 3px !important;
  height: 3px !important;
}
::-webkit-scrollbar-track {
  background: #e2e2e2;
}

::-webkit-scrollbar-thumb {
  background: #a5a5a5;
  border-radius: 8px;
}

::-webkit-scrollbar-thumb:hover {
  background: #bbb;
}

/* Media Querys */
@media only screen and (max-width: 1300px) {
  .btn-ai {
    right: 6vw;
  }
}

@media only screen and (max-width: 1100px) {
  .btn-ai {
    right: 7vw;
  }
}

@media only screen and (max-width: 900px) {
  .btn-ai {
    right: 8.5vw;
  }
}
@media only screen and (max-width: 700px) {
  .btn-ai {
    right: 10.5vw;
  }
}
@media only screen and (max-width: 620px) {
  .btn-ai {
    display: none;
  }
}
</style>